import React from "react";
import Layout from "../components/layout";
import Image from "../components/elements/Image";
import { Title } from "../styles/typography.styles";
import { Header, Text, Block, BlockRow, BlockCard, BlockCardTitle, BlockCardLine } from "../components/service/service.styles";
import { SlideContent, SlideText, SlideTitle } from "../components/home/header.styles";
import { ImageAngle  } from "../components/models/models.styles";
import { ContactBottom, ContactBottomImg, ContactInfo, Line } from "../components/contact/contact.styles";
import ServiceForm from "../components/contact/service-form";
import ContactSocial from "../components/contact/contact-social";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const Service = () => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={'6Lfc3N0ZAAAAAMTwEgiMjq1SiikUToWBuU_jGHXb'}>
    <Layout title={'Serwis'}>
      <Header>
        <Image fileName={"serwis_banner_01@2x.jpg"} alt={"Serwis Dodge & Ram"}/>
        <div className="container">
          <SlideContent>
            <SlideTitle>Serwis Dodge & Ram</SlideTitle>
            <SlideText>Autoryzowany dealer i serwis <br/>
              pojazdów Dodge i Ram</SlideText>
          </SlideContent>
        </div>
      </Header>

      <main>
        <Title>Serwis Dodge & Ram</Title>
        <Text>
          <p>
            Jesteśmy autoryzowanym dealerem i serwisem pojazdów Dodge i Ram. Stawiamy na dokładność wykonywanych usług dlatego i nasz personel jest dokładnie wyselekcjonowany by zagwarantować naszym klientom maksymalny poziom satysfakcji.
          </p>
          <p>
            Nasza załoga dokładnie zna amerykańską motoryzację a pojazdy marki Dodge’a i Ram nie mają przed nami żadnych tajemnic. Stosujemy tylko oryginalne części MOPAR by jak najlepiej zadbać o Twój pojazd i zapewnić odpowiednie jego działanie.Postaw na autoryzowany serwis by mieć gwarancję jakości wykonywanych usług.
          </p>
        </Text>

        <ImageAngle>
          <Image fileName={"2021_serwis_redeye@2x.jpg"} alt={"Serwis Dodge & Ram"}/>
        </ImageAngle>

        <Block>
          <Title>Oferujemy profesjonalne usługi:</Title>

          <BlockRow className={`container`}>
            <BlockCard>
              <Image fileName={'service1.jpg'} />
              <BlockCardTitle>NAPRAWY GWARANCYJNE <br/> I POGWARANCYJNE</BlockCardTitle>
              <BlockCardLine/>
            </BlockCard>
            <BlockCard>
              <Image fileName={'service2.jpg'} />
              <BlockCardTitle>Serwis <br/> akcje serwisowe</BlockCardTitle>
              <BlockCardLine/>
            </BlockCard>
            <BlockCard>
              <Image fileName={'service3.jpg'} />
              <BlockCardTitle>montaż dodatkowych <br/> akcesoriów</BlockCardTitle>
              <BlockCardLine/>
            </BlockCard>
            <BlockCard>
              <Image fileName={'service4.jpg'} />
              <BlockCardTitle>inne usługi <br/> na życzenie Klienta</BlockCardTitle>
              <BlockCardLine/>
            </BlockCard>
          </BlockRow>
        </Block>

        <ContactBottom style={{background: "none"}}>
          <div className="container">
            <ContactInfo>
              <h2>Umów się na serwis</h2>

              <h4>serwis i części</h4>
              <Line marginBottom="2rem"/>

              <strong>Marek Świeboda</strong>
              <span>tel.: <a href="tel:+48532081814">+48 532 081 814</a></span>
              <span>e-mail: <a href="mailto:serwis@dodge.rzeszow.pl">serwis@dodge.rzeszow.pl</a></span>

              <ContactSocial/>
            </ContactInfo>

            <ServiceForm/>

            <ContactBottomImg>
              <img src="/images/kontakt_bg_ram.png" alt=""/>
            </ContactBottomImg>
          </div>
        </ContactBottom>
      </main>

    </Layout>
    </GoogleReCaptchaProvider>
  )
}

export default Service