import styled from "styled-components";
import { breakpoints } from "../../styles/breakpoints.styles";

export const Header = styled.header`
  width: 100%;
  height: 30vw;
  min-height: 24rem;
  margin-top: 16rem;
  position: relative;
  
  ${breakpoints('tabLand') `
    margin-top: 8.2rem;
  `} 
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .container {
    position: relative;
    
    ${breakpoints('xs') `
      position: absolute;
      top: 0;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    `} 
  }
`;

export const Text = styled.div`
  color: ${props => props.theme.colors.text};
  font-size: 1.6rem;
  line-height: 2.4rem;
  max-width: 96rem;
  text-align: center;
  margin: 0 auto 7.2rem;
  
  p {
    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }
`;

export const Block = styled.div`
  width: 100%;
  background-color: #E3E3E3;
  padding: 2px 0 4rem;
  margin-top: 4rem;
`;

export const BlockRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`;

export const BlockCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 23%;
  
  ${breakpoints('tabLand') `
    width: 48%
  `} 
  ${breakpoints('phone') `
    width: 30rem
  `} 
`;

export const BlockCardTitle = styled.div`
  color: black;
  font-family: ${props => props.theme.fonts.heading};
  font-size: 2.6rem;
  font-weight: 500;
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-transform: uppercase;
  
  ${breakpoints('tabLand') `
    font-size: 2.2rem;
  `} 
`;

export const BlockCardLine = styled.div`
  width: 8rem;
  height: 2px;
  background-color: ${props => props.theme.colors.primary};
  
  ${breakpoints('tabLand') `
    margin-bottom: 4rem
  `} 
`;