import React, { useState } from "react";
import axios from 'axios';
import FormInput from "../elements/input";
import Checkbox from "../elements/checkbox";
import Button from "../elements/button";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Form, FormInfo } from "./contact.styles";

const ServiceForm = () => {
  const [inputs, setInputs] = useState({
    email: '',
    message: ''
  })
  const [status, setStatus] = useState({
    submitting: false,
    info: { error: false, success: false, msg: null }
  })
  const [error, setError] = useState(false)
  const [privacy, setPrivacy] = useState(false)
  const [marketing, setMarketing] = useState(false)
  const [commercial, setCommercial] = useState(false)

  const { executeRecaptcha } = useGoogleReCaptcha()

  const handleResponse = (status, msg) => {
    if (status === 200) {
      setStatus({
        submitting: false,
        info: { error: false, success: true, msg: msg }
      })
      setInputs({
        email: '',
        message: ''
      })
      setPrivacy(false)
      setMarketing(false)
      setCommercial(false)
    } else {
      setStatus({
        info: { error: true, success: false, msg: msg }
      })
    }
    console.log(msg)
  }

  const handleOnChange = e => {
    e.persist()
    setInputs(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }))
    setStatus({
      submitting: false,
      info: { error: true, success: false, msg: null }
    })
  }

  const handleOnSubmit = async e => {
    e.preventDefault()
    if (!executeRecaptcha) { return; }

    const result = await executeRecaptcha("homepage");

    setInputs({ ...inputs, token: result })

    if (inputs.email !== '' &&
        inputs.message !== ''&&
        privacy
    ) {

      const data = { ...inputs, token: result }

      setStatus(prevStatus => ({ ...prevStatus, submitting: true }))

      await axios.post('/sendService.php', data)
          .then(res => {
            console.log(res.data)
            console.log(status)
            handleResponse(res.data.status, res.data.text)
          })

    } else {
      setError('Proszę wypełnić wszystkie pola')
    }
  }

  const handleCheck = () => setPrivacy(!privacy)
  const handleCheck2 = () => setMarketing(!marketing)
  const handleCheck3 = () => setCommercial(!commercial)

  return (
      <Form>
        <FormInput type={'email'}
                   name={'email'}
                   label={'Twój e-mail*'}
                   value={inputs.email}
                   required={true}
                   success={status.info.success}
                   handleChange={handleOnChange}/>
        <FormInput type={'text'}
                   name={'message'}
                   label={'Treść wiadomości*'}
                   value={inputs.message}
                   required={true}
                   success={status.info.success}
                   handleChange={handleOnChange}/>

        <FormInfo>
          Przed wysłaniem nam swoich danych, koniecznie zapoznaj się z Polityką Prywatności, w której znajdziesz informacje o administratorze danych osobowych, Twoich prawach i naszych obowiązkach, w jakim celu przetwarzane są Twoje dane oraz wszelkie inne informacje, które dotyczą ochrony i bezpieczeństwa Twoich danych osobowych.
        </FormInfo>

        <Checkbox name={'privacy'}
                  label={'Akceptuję <a href="/privacy" target="_blank">Politykę prywatności*</a>'}
                  handleChange={handleCheck}
                  value={privacy}/>

        <Checkbox name={'marketing'}
                  label={'Wyrażam zgodę na przetwarzanie moich danych osobowych dla celów marketingowych przez Dodge & RAM Rzeszów z siedzibą w Rzeszowie przy ul. Ludwika Chmury 4.'}
                  handleChange={handleCheck2}
                  value={marketing}/>

        <Checkbox name={'commercial'}
                  label={'Wyrażam zgodę na przesyłanie mi informacji handlowych drogą elektroniczną Dodge & RAM Rzeszów z siedzibą w Rzeszowie przy ul. Ludwika Chmury 4., zgodnie z ustawą o świadczeniu usług drogą elektroniczną. Mam świadomość, że mogę cofnąć zgodę w każdym momencie.'}
                  handleChange={handleCheck3}
                  value={commercial}/>

        <Button handleSubmit={handleOnSubmit} sending={status.submitting} success={status.info.success}>
          {status.info.success ?
              'Wysłano' :
              'Wyślij wiadomość'
          }
        </Button>
        <span>{error && error}</span>
      </Form>
  )
}

export default ServiceForm
